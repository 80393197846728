body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: black;
}

body::-webkit-scrollbar-thumb {
  background-color: #f5edce;
  border-radius: 10px;
}

@font-face {
  font-family: root-font;
  src: url("./assets/fonts/Aclonica.ttf");
}

@font-face {
  font-family: main-font;
  src: url("./assets/fonts/BebasNeue-Regular.ttf");
}

@font-face {
  font-family: button-font;
  src: url("./assets/fonts/Louis\ George\ Cafe.ttf");
}

* {
  font-family: root-font;
  letter-spacing: 1.3px;
  font-weight: 500;
}

.ant-layout-header {
  padding-inline: 0px;
  height: 100px;
  /* background-color: #1a0000 !important; */
}

/* @media screen and (max-width: 320px) {
  .ant-layout-header {
    padding: 0px 10px !important;
  }
} */

.black-bg {
  background-color: black;
}

.pointer {
  cursor: pointer;
}

.header-sticky {
  position: sticky;
  top: 0;
  z-index: 2;
  /* box-shadow: 0px 2px rgb(123, 122, 122); */
  /* min-height: 74px; */
  /* justify-content: center; */
}

.font-color-one {
  color: #f5edce;
}

.font-color-two {
  color: #a2a4a9;
}

.font-family-two {
  font-family: button-font !important;
}

.font-family-one {
  font-family: root-font;
}

.transition-p-5 {
  transition: 0.5s all;
}

.font-logo:hover {
  color: #5b5d60;
}

.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.ml-5 {
  margin-left: 5px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mr-5 {
  margin-right: 5px;
}

.p-5 {
  padding: 5px;
}

.p-25 {
  padding: 25px;
}

.color-white {
  color: white;
}

.color-black {
  color: black !important;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-17 {
  font-size: 17px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-23 {
  font-size: 23px;
}

.font-size-25 {
  font-size: 25px;
}

.font-size-30 {
  font-size: 30px;
}

.font-weight-600 {
  font-weight: 600;
}

.width-100 {
  width: 100%;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

/* Button CSS */
.button-style {
  cursor: pointer;
  position: relative;
  padding: 4px 15px !important;
  font-size: 18px;
  color: #f5edce;
  border: 2px solid #f5edce;
  border-radius: 34px;
  background-color: transparent;
  font-weight: 600;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  overflow: hidden;
}

.button-style::before {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  border-radius: inherit;
  scale: 0;
  z-index: -1;
  background-color: #f5edce;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.button-style:hover::before {
  scale: 7;
}

.button-style:hover {
  color: #212121;
  scale: 1.1;
  box-shadow: 0 0px 20px rgba(193, 163, 98, 0.4);
}

.button-style:active {
  scale: 1;
}

.underline {
  /* text-decoration: underline;
  text-decoration-thickness: 1.5px;
  text-decoration-color: white; */
  border-bottom: 1px dashed;
  border-image: linear-gradient(-225deg,
      #eeb02b 0%,
      #3dd54c 29%,
      #ff1361 67%,
      #fff800 100%);
  border-image-slice: 1;
}

.links:hover {
  color: gray;
}

.card-bg {
  background-color: #f5edce !important;
}

.content-style {
  /* background-color: #89c4e1; */
  background-color: black;
  min-height: 79.2vh;
}

.footer-style {
  /* background-color: #1a0000; */
  background-color: #001529;
  /* position: fixed; */
  /* bottom: 0; */
  width: 100%;
}

.footer-text {
  font-family: root-font;
}

/* ANTD - Table */
/* Table empty No Data */
.ant-empty-description {
  color: #adadad !important;
  font-size: large;
}

.ant-table-thead>tr>th {
  background-color: #111111 !important;
  color: #adadad !important;
  font-size: medium;
  letter-spacing: 0.7px;
}

.ant-table-tbody>tr>td {
  color: #e1e1e1 !important;
  background-color: #282a36 !important;
  /* font-size: 20px; */
  font-weight: 600;
  letter-spacing: 1.3px;
  border-bottom: 0.01px solid #494c64 !important;
}

.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-thead>tr>td {
  border-bottom: none !important;
}

.ant-table-wrapper .ant-table {
  background: none !important;
}

.ant-table-wrapper .ant-table-tbody .ant-table-row>.ant-table-cell-row-hover {
  background-color: #2f3242 !important;
}

.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
.ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  height: 2.7em;
  background-color: #35384a;
}

.ant-pagination-item,
.ant-pagination-item-active,
.ant-pagination-item-link {
  background-color: aliceblue !important;
}

/* Divider */
.ant-divider {
  background-color: aliceblue;
}

/* Tabs */

.ant-tabs-content-holder {
  /* background-color: #1a0000; */
  background-color: #001529c2;
  /* background: transparent; */
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(3px);
  border-radius: 35px;
  margin-bottom: 70px;
  padding: 25px;
  min-height: 60vh;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
  align-items: center;
  display: flex;
  gap: 80px;
}

@media screen and (max-width: 576px) {

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
    align-items: center;
    display: flex;
    gap: 40px;
  }
}

@media screen and (max-width: 455px) {

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
    align-items: center;
    display: flex;
    gap: 10px;
  }
}

@media screen and (max-width: 365px) {

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
    align-items: center;
    display: flex;
    gap: 0px;
  }
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  /* color: #7a09d7; */
  color: #b76bf5;
  text-shadow: 0 0 0.25px currentcolor;
  font-size: 20px;
}

.ant-tabs .ant-tabs-tab {
  font-size: 20px;
  color: white;
  padding: 10px 0px 0px !important;

}

@media screen and (max-width: 330px) {
  .ant-tabs .ant-tabs-tab {
    font-size: 18px;
  }
}

.ant-tabs .ant-tabs-tab:hover {
  /* color: #7a09d7; */
  color: #b76bf5;
  text-shadow: 0 0 0.25px currentcolor;
  font-size: 20px;
}

.ant-tabs .ant-tabs-ink-bar {
  position: absolute;
  /* background: #7a09d7; */
  background: #b76bf5;
  pointer-events: none;

  /* gap: 80px; */
  /* left: 486px;
  transform: translateX(-50%);
  width: 54px; */
}

.ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible),
.ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible),
.ant-tabs .ant-tabs-tab-btn:active,
.ant-tabs .ant-tabs-tab-remove:active {
  /* color: #7a09d7; */
  color: #b76bf5
}

.ant-tabs-top>.ant-tabs-nav::before,
.ant-tabs-bottom>.ant-tabs-nav::before,
.ant-tabs-top>div>.ant-tabs-nav::before,
.ant-tabs-bottom>div>.ant-tabs-nav::before {
  border-bottom: none;
}

/* ANTD - Skeleton */
.ant-skeleton-title {
  background-color: #616161ab !important;
}

.ant-skeleton-paragraph>li {
  background-color: #616161ab !important;
}

.ant-card .ant-card-body {
  padding: 40px 8px;
}

.card-bg>.ant-card-body {
  padding: 15px 8px;
}

.antd-card-body-pad {
  padding: 20px 10px !important;
}

.card-ordinals {
  width: 100% !important;
}

.card-ordinals>.ant-card>.ant-card-body {
  padding: 20px;
}

.icon-alignment {
  display: flex;
  align-items: center;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.launchIconStyle {
  border: 1px solid red;
  padding: 2px;
  background-color: rgb(240, 213, 213);
  border-radius: 3px;
}

.launchIconStyle-one {
  border: 1px solid #008000;
  padding: 2px;
  background-color: #ceecce;
  border-radius: 3px;
}

.scale-animation {
  transition: transform .2s;
  /* Animation */
}

.scale-animation:hover {
  transform: scale(1.2);
}

.activate-button {
  background-color: #0dce71;
  color: #0d6f0d;
  border: none;
  outline: none;
}

.scale-activate-button:hover {
  scale: 1 !important;
}

.border-radius-5 {
  border-radius: 5px;
}

.border-radius-25 {
  border-radius: 25px;
}

.border-radius-50 {
  border-radius: 100px;
}

.border-black-3 {
  border: 3px solid black;
}

.icon-float-3s {
  animation: float 3s ease-in-out infinite;
}

.icon-float-4s {
  animation: float 3.2s ease-in-out infinite;
}

@keyframes float {
  0% {
    /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
    transform: translatey(0px);
    /* padding: 0 8px; */
  }

  50% {
    /* box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2); */
    transform: translatey(-20px);
    /* padding: 0 8px; */
  }

  100% {
    /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
    transform: translatey(0px);
    /* padding: 0 8px; */
  }
}

.btn .animation {
  /* width: 0px; */
  width: 70px;
  background-color: rgb(255 255 255 / 82%);
  animation: ripple 1s linear infinite;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1),
      0 0 0 20px rgba(255, 255, 255, 0.358),
      0 0 0 40px rgba(255, 255, 255, 0.555), 0 0 0 60px rgba(255, 255, 255, 0.1);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.407),
      0 0 0 40px rgba(255, 255, 255, 0.341),
      0 0 0 60px rgba(255, 255, 255, 0.338),
      0 0 0 80px rgba(255, 255, 255, 0.272);
  }
}

.ant-typography {
  font-family: root-font;
}

.card {
  width: 300px;
  height: 270px;
  border-radius: 20px;
  background: #f5f5f5;
  position: relative;
  padding: 1.8rem;
  border: 2px solid #c3c6ce;
  transition: 0.5s ease-out;
  overflow: visible;
}

@media screen and (max-width: 390px) {
  .card {
    width: 240px;
    height: 190px;
  }
}

.card-details {
  color: black;
  height: 100%;
  gap: 0.5em;
  display: grid;
  place-content: center;
}

.card-button {
  transform: translate(-50%, 125%);
  /* width: 60%; */
  border-radius: 1rem;
  border: none;
  /* background-color: #008bf8; */
  color: #fff;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  position: absolute;
  left: 50%;
  bottom: 0;
  opacity: 0;
  transition: 0.3s ease-out;
}

.text-body {
  color: rgb(134, 134, 134);
}

/*Text*/
.text-title {
  font-size: 1.5em;
  font-weight: bold;
}

/*Hover*/
.card:hover {
  border-color: #008bf8;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
}

.card:hover .card-button {
  transform: translate(-50%, 140%);
  opacity: 1;
}

.image {
  position: fixed !important;
  top: 40% !important;
  left: 47% !important;
  width: 120px !important;
  height: 135px !important;
  /* margin: -60px 0 0 -60px; */
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.color-violet {
  color: #b458ff;
}

.color-violet-one {
  color: #c37afe;
}

.typewriter {
  color: #fff;
  overflow: hidden;
  /* border-right: .15em solid orange; */
  white-space: nowrap;
  margin: 0 auto;
  animation: typing 3.5s steps(30, end), blink-caret 0.5s none infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 55%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {

  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: orange;
  }
}

/* modal style */

.ant-modal .ant-modal-content {
  background-color: rgb(12, 3, 19) !important;
  box-shadow: 0 0 5px 0.5px #aabcd8, 0 0 5px 1px #dce1e833 !important;
}

.ant-modal {
  top: 180px;
}

.ant-modal-close {
  color: #ff9900 !important;
  border: 1px solid !important;
  border-radius: 10px !important;
  height: 30px !important;
  width: 30px !important;
  margin-top: 6px !important;
}

.ant-modal-close:hover {
  background-color: #ff9900 !important;
  color: yellow !important;
  border-radius: 20px !important;
}

.modalBoxYellowShadow {
  background-color: #4c4a1f !important;
  border-color: 1px solid #29190e !important;
  padding: 10px 10px;
  border-radius: 3px;
  color: #dde747 !important;
}

/* //input style */

.inputStyle {
  background-color: #282a36 !important;
  color: white !important;
  border: transparent !important;
  outline: none !important;
}

.ant-input {
  background-color: #282a36 !important;
  color: #ababab !important;
}

.text-color-two {
  color: #adadad;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}

.transfer-button:hover {
  background-color: orange;
  border: none;
}

.twitter-button {
  width: 100%;
  background-color: #2f49d4;
  color: white;
  border: none;
}

.twitter-button:hover {
  background-color: #b458ff;
}

/* antd notify */
.ant-notification .ant-notification-notice-wrapper {
  background: none !important;
}

/* Notify background */
.ant-notification .ant-notification-notice {
  background: -webkit-linear-gradient(#013a63, #166c8f) !important;
  border-radius: 15px !important;
}

.ant-notification-notice-close-x {
  cursor: pointer;
}

/* Notify text color */
.ant-notification .ant-notification-notice .ant-notification-notice-message {
  color: white !important;
}

/* Cut off the extra margin in notify text */
.ant-notification .ant-notification-notice .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 0 !important;
}

.ant-notification-notice-close-x {
  color: white;
}

/* Heading in Mint color animation */
.animate-charcter {
  text-transform: uppercase;
  /* background-image: linear-gradient(-225deg,
      #231557 0%,
      #44107a 29%,
      #ff1361 67%,
      #fff800 100%); */
  background-image: linear-gradient(-225deg,
      #eeb02b 0%,
      #3dd54c 29%,
      #ff1361 67%,
      #fff800 100%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 3s linear infinite;
  display: inline-block;
}

@keyframes textclip {
  to {
    background-position: -200% center;
  }
}

.ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background: transparent;
  border: 3px solid #3c3c3c;
  border-radius: 50%;
  text-align: center;
  line-height: 150px;
  font-family: sans-serif;
  font-size: 20px;
  color: #fff000;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow: 0 0 10px #fff000;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.ring:before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-top: 3px solid #fff000;
  border-right: 3px solid #fff000;
  border-radius: 50%;
  animation: animateC 2s linear infinite;
}

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.snow,
.snow:before,
.snow:after {
  position: absolute;
  overflow: hidden;
  top: -600px;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: 600px 600px;
  animation: snow 3s linear infinite;
  content: "";
}

.snow:after {
  /* z-index: -1; */
  margin-left: -200px;
  opacity: 0.4;
  animation-duration: 6s;
  animation-direction: reverse;
  filter: blur(3px);
}

.snow:before {
  animation-duration: 9s;
  animation-direction: reverse;
  opacity: 0.65;
  filter: blur(1.5px);
  margin-left: -300px;
  /* z-index: -1; */

}

@keyframes now {
  to {
    transform: translateY(80px);
  }
}

@keyframes rune {
  to {
    transform: translateY(152px);
  }
}

/* Generated radial gradients */

.snow {
  /* z-index: 1; */
  background-image: radial-gradient(5px 5px at 209px 73px, rgba(232, 17, 17, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(5px 5px at 374px 357px, rgba(205, 18, 18, 0.6) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(5px 5px at 101px 90px, rgba(231, 27, 27, 0.6) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(4px 4px at 206px 186px, rgba(130, 232, 13, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(5px 5px at 489px 149px, rgba(115, 230, 15, 0.6) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(5px 5px at 353px 115px, rgba(26, 209, 53, 0.6) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(4px 4px at 560px 356px, rgba(70, 20, 236, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(5px 5px at 372px 49px, rgba(33, 47, 241, 0.6) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(3px 3px at 374px 199px, rgba(77, 26, 231, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(4px 4px at 137px 431px, rgba(242, 18, 238, 0.6) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(5px 5px at 354px 312px, rgba(247, 25, 243, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(4px 4px at 432px 442px, rgba(242, 21, 220, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(3px 3px at 169px 228px, rgba(233, 247, 34, 0.6) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(3px 3px at 387px 429px, rgba(222, 232, 90, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(4px 4px at 242px 332px, rgba(213, 243, 17, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(4px 4px at 137px 377px, rgba(252, 169, 15, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(3px 3px at 580px 467px, rgba(218, 139, 13, 0.6) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(5px 5px at 370px 264px, rgba(238, 171, 70, 0.6) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(5px 5px at 276px 306px, rgba(60, 233, 196, 0.6) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(4px 4px at 380px 173px, rgba(35, 233, 180, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(3px 3px at 212px 60px, rgba(31, 222, 200, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(5px 5px at 443px 434px, rgba(255, 255, 255, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(5px 5px at 342px 44px, rgba(255, 255, 255, 0.6) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(4px 4px at 41px 108px, rgba(255, 255, 255, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(4px 4px at 58px 274px, rgba(255, 255, 255, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(5px 5px at 455px 155px, rgba(255, 255, 255, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(4px 4px at 198px 283px, rgba(255, 255, 255, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(3px 3px at 31px 69px, rgba(255, 255, 255, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(3px 3px at 560px 169px, rgba(255, 255, 255, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(5px 5px at 160px 112px, rgba(255, 255, 255, 0.6) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(3px 3px at 541px 49px, rgba(255, 255, 255, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(4px 4px at 481px 369px, rgba(255, 255, 255, 0.6) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(4px 4px at 153px 469px, rgba(255, 255, 255, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(4px 4px at 243px 253px, rgba(255, 255, 255, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(4px 4px at 72px 350px, rgba(255, 255, 255, 0.6) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(3px 3px at 484px 29px, rgba(255, 255, 255, 0.6) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(4px 4px at 317px 373px, rgba(255, 255, 255, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(5px 5px at 444px 424px, rgba(255, 255, 255, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(5px 5px at 556px 408px, rgba(255, 255, 255, 0.6) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(3px 3px at 441px 226px, rgba(255, 255, 255, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(4px 4px at 419px 344px, rgba(255, 255, 255, 0.7) 50%, rgba(0, 0, 0, 0)),
    radial-gradient(3px 3px at 454px 367px, rgba(255, 255, 255, 0.7) 50%, rgba(0, 0, 0, 0));
}

.ant-tabs-centered>.ant-tabs-nav .ant-tabs-nav-wrap:not([class*=".ant-tabs-nav-wrap-ping"]),
.ant-tabs-centered>div>.ant-tabs-nav .ant-tabs-nav-wrap:not([class*=".ant-tabs-nav-wrap-ping"]) {
  justify-content: center;
  z-index: 1;
  margin-top: 6px;
}

.ant-tabs-top>.ant-tabs-nav,
.ant-tabs-bottom>.ant-tabs-nav,
.ant-tabs-top>div>.ant-tabs-nav,
.ant-tabs-bottom>div>.ant-tabs-nav {
  margin: 0 0 18px 0;
}

/* Rocket CSS */
/*rocket animation*/
@-webkit-keyframes center-flame {
  0% {
    transform: scale(0, 0.8)
  }

  50% {
    transform: scale(0.5, 1)
  }

  100% {
    transform: scale(0, 0.4)
  }
}

@-moz-keyframes center-flame {
  0% {
    transform: scale(0, 0.8)
  }

  50% {
    transform: scale(0.5, 1)
  }

  100% {
    transform: scale(0, 0.4)
  }
}

@-o-keyframes center-flame {
  0% {
    transform: scale(0, 0.8)
  }

  50% {
    transform: scale(0.5, 1)
  }

  100% {
    transform: scale(0, 0.4)
  }
}

@keyframes center-flame {
  0% {
    transform: scale(0, 0.8)
  }

  50% {
    transform: scale(0.5, 1)
  }

  100% {
    transform: scale(0, 0.4)
  }
}

@-webkit-keyframes side-flame {
  0% {
    transform: scale(0, 1)
  }

  50% {
    transform: scale(1, 1)
  }

  100% {
    transform: scale(0, 0.4)
  }
}

@-moz-keyframes side-flame {
  0% {
    transform: scale(0, 1)
  }

  50% {
    transform: scale(1, 1)
  }

  100% {
    transform: scale(0, 0.4)
  }
}

@-o-keyframes side-flame {
  0% {
    transform: scale(0, 1)
  }

  50% {
    transform: scale(1, 1)
  }

  100% {
    transform: scale(0, 0.4)
  }
}

@keyframes side-flame {
  0% {
    transform: scale(0, 1)
  }

  50% {
    transform: scale(1, 1)
  }

  100% {
    transform: scale(0, 0.4)
  }
}

ellipse.center-flame {
  -webkit-animation: center-flame .6s ease infinite;
  -moz-animation: center-flame .6s ease infinite;
  -o-animation: center-flame .6s ease infinite;
  animation: center-flame .6s ease infinite;
  transform-origin: center top;
}

ellipse.side-flame {
  -webkit-animation: side-flame .4s ease infinite;
  -moz-animation: side-flame .4s ease infinite;
  -o-animation: side-flame .4s ease infinite;
  animation: side-flame .4s ease infinite;
  transform-origin: center top;
}

.rocketship {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.rocket {
  width: 30%;
}

/* Shine */
.shine {
  animation: shines 4s linear infinite;
}

.shine {
  content: "";
  display: block;
  /* width: 0px; */
  height: 86%;
  position: absolute;
  top: 7%;
  left: 0%;
  opacity: 0;
  background: white;
  box-shadow: 0 0 5px 3px white;
  transform: skewX(-20deg);
}

@keyframes shines {
  0% {
    opacity: 1;
    left: 0%;
  }

  60% {
    opacity: 1;
    left: 100%;
  }


  100% {
    opacity: 0;
    left: 110%;
  }
}

/* booster-container */
.booster-container {
  border-radius: 20px 0px 20px 0px;
  background-color: #130f40;
  background-image: linear-gradient(315deg, #130f40 0%, #000000 74%);
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.55);
  padding: 30px;
  position: relative;
  cursor: pointer;
  transition: all .3s;
}

.booster-container:after {
  position: absolute;
  content: "";
  width: 0.40rem;
  inset: 0.65rem auto 0.65rem 0.5rem;
  border-radius: 0rem 0.525rem 0.525rem 0rem;
  background: linear-gradient(to bottom, #2eadff, #3d83ff, #7e61ff);
  transition: transform 300ms ease;
  z-index: 4;
}

.booster-container:hover {
  transform: scale(0.97);
  /* transform: translateX(0.15rem); */
}

.booster-container .tag {
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(to bottom, #2eadff, #3d83ff, #7e61ff);
  border-radius: 99em 0 0 99em;
  display: flex;
  align-items: center;
  padding: 0.625em 0.75em;
  font-size: 1.25rem;
  font-weight: 600;
  color: white;
}